import { ApolloError } from "@apollo/client";
import noop from "lodash/noop";
import { useState, useEffect, useCallback, useMemo, useContext, createContext } from "react";
import { MarkRequired } from "ts-essentials";
import { z } from "zod";

import { BookingAnswerInput, BookingAvailabilityProcessState } from "@holibob-packages/graphql-types";
import { PhoneNumberFormattedE164Schema } from "@holibob-packages/phone-number";

import { BookingFormProviderParams } from "../..";
import { Booking, BookingAvailability, BookingQuestion } from "../../../apiHooks/useBooking";
import useBookingManager, { BookingCommitParams, BookingManagerReturnValue } from "../../../apiHooks/useBookingManager";
import { useNextTranslation } from "../../../hooks/useNextTranslation";
import { noopAsync } from "../../../utils/noopAsync";

export type BookingFormValues = {
    reference: string | null;
    leadPassengerName: string | null;
    questions: Record<string, string | null>;
    isTermsAccepted: boolean | null;
};

export const BookingChargeTypeSchema = z.enum(["charge", "refund", "zero"]);
export type BookingChargeType = z.infer<typeof BookingChargeTypeSchema>;

export type BookingFormContextValue = Required<
    Pick<
        BookingManagerReturnValue,
        "settings" | "permissions" | "isCommitting" | "bookingDeleteAvailability" | "bookingCommit" | "bookingCancel"
    >
> & {
    booking?: Booking;
    loading: boolean;
    error?: ApolloError;
    termsAccepted: boolean;
    showPaymentModal: boolean;
    initialValues?: BookingFormValues;
    isComplete?: boolean;
    isExpired?: boolean;
    showConfirmModal: boolean;
    refetch: () => void;
    setTermsAccepted: (isTermsAccepted: boolean) => void;
    setShowPaymentModal: (showPaymentModal: boolean) => void;
    setShowConfirmModal: (showConfirmModal: boolean) => void;
    onQuestionsAnswered: (questions: BookingFormValues["questions"]) => void;
    onSubmit: ((values: BookingFormValues, options?: { shouldSetDidSubmit?: boolean }) => void) | null;
    onCommit: (params?: BookingCommitParams) => Promise<void>;
};

const DEFAULT_SETTINGS: MarkRequired<BookingManagerReturnValue, "settings">["settings"] = {
    siteOwnerName: undefined,
    exitManageBookingUrl: undefined,
    exitManageBookingText: undefined,
    defaultCountryIsoCode: undefined,
    requireBookingExternalReference: false,
    requireExternalTransactionReference: false,
    showCancelEntireBookingButton: false,
    isConsumerFacing: false,
    isProduction: false,
    isHolibobAdmin: false,
    showBookingAvailabilityStatus: false,
};

const DEFAULT_PERMISSIONS: MarkRequired<BookingManagerReturnValue, "permissions">["permissions"] = {
    canCreate: false,
    canRemove: false,
    canUpdate: false,
    canForceCancel: false,
    canPreviewFiles: false,
};

export const BookingFormContext = createContext<BookingFormContextValue>({
    booking: undefined,
    isCommitting: false,
    loading: false,
    error: undefined,
    refetch: noopAsync,
    bookingDeleteAvailability: noopAsync,
    bookingCommit: noopAsync,
    bookingCancel: noopAsync,
    setTermsAccepted: noop,
    termsAccepted: false,
    showPaymentModal: false,
    setShowPaymentModal: noop,
    initialValues: undefined,
    isComplete: false,
    isExpired: false,
    showConfirmModal: false,
    setShowConfirmModal: noop,
    settings: DEFAULT_SETTINGS,
    permissions: DEFAULT_PERMISSIONS,
    onQuestionsAnswered: noop,
    onSubmit: noop,
    onCommit: noopAsync,
});

export const PENDING_AVAILABILITY_STATES: BookingAvailabilityProcessState[] = [
    BookingAvailabilityProcessState.PendingAmendment,
    BookingAvailabilityProcessState.PendingCancellation,
];

type BookingFormComputeInitialValuesParams = {
    booking: Booking | undefined;
    isTermsAccepted: boolean | null;
};

function useBookingFormComputeInitialValues(params: BookingFormComputeInitialValuesParams) {
    const { booking, isTermsAccepted } = params;

    return useMemo(() => {
        if (!booking) return;

        const { reference, leadPassengerName, availabilities, bookingQuestions } = booking;

        const initialValues: BookingFormValues = {
            reference,
            leadPassengerName,
            questions: {},
            isTermsAccepted: isTermsAccepted ?? false,
        };

        bookingQuestions.forEach((question) => {
            initialValues.questions[question.id] = question.answerValue;
        });

        availabilities.forEach((availability) => {
            const { personList, questionList } = availability;
            const { nodes: questions } = questionList;
            const { nodes: persons } = personList;

            questions.forEach((question) => {
                initialValues.questions[question.id] = question.answerValue;
            });

            persons.forEach((person) => {
                person.questionList.nodes.forEach((question) => {
                    initialValues.questions[question.id] = question.answerValue;
                });
            });
        });

        return initialValues;
    }, [booking, isTermsAccepted]);
}

function useCreateBookingFormOnSubmit(
    booking: Booking | undefined,
    setInput: BookingManagerReturnValue["setInput"],
    setDidSubmit: (didSubmit: boolean) => void
) {
    const onSubmit = useCallback(
        (values: BookingFormValues, params?: { shouldSetDidSubmit?: boolean }) => {
            const shouldSetDidSubmit = params?.shouldSetDidSubmit ?? true;

            const state = booking?.state;

            if (!booking?.useLifecycleManager && state !== "OPEN") {
                setInput(null);
            } else {
                const { reference, leadPassengerName, questions } = values;
                const answerList: BookingAnswerInput[] = Object.entries(questions)
                    .filter(([, value]) => value)
                    .map(([questionId, value]) => ({ questionId, value: value! }));

                const input = { leadPassengerName, reference, answerList };
                setInput(input);
            }

            if (shouldSetDidSubmit) {
                setDidSubmit(true);
            }
        },
        [booking, setInput, setDidSubmit]
    );

    return booking ? onSubmit : null;
}

type UseBookingFormPaymentPendingParams = {
    booking?: Booking;
    didSubmit: boolean;
    showPaymentModal: boolean;
    setShowPaymentModal: BookingFormContextValue["setShowPaymentModal"];
    setDidSubmit: (didSubmit: boolean) => void;
};

function useBookingFormPaymentPending({
    booking,
    didSubmit,
    showPaymentModal,
    setShowPaymentModal,
    setDidSubmit,
}: UseBookingFormPaymentPendingParams) {
    const state = booking?.state;
    const canCommit = booking?.canCommit;
    const useLifecycleManager = booking?.useLifecycleManager;

    const paymentPending = useMemo(() => {
        return (
            state &&
            didSubmit &&
            canCommit &&
            (useLifecycleManager ? true : ["OPEN", "PAYMENT"].includes(state)) &&
            !showPaymentModal
        );
    }, [didSubmit, canCommit, state, showPaymentModal, useLifecycleManager]);

    useEffect(() => {
        if (!paymentPending) return;

        setShowPaymentModal(true);
        setDidSubmit(false);
    }, [paymentPending, setShowPaymentModal, setDidSubmit]);
}

export function useBookingFormBaseContextCreate(
    bookingManagerData: BookingManagerReturnValue
): BookingFormContextValue {
    const {
        setInput,
        refetch,
        booking,
        isCommitting,
        bookingDeleteAvailability,
        bookingCommit,
        bookingCancel,
        loading,
        error,
        settings,
        permissions,
    } = bookingManagerData;
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [didSubmit, setDidSubmit] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const state = booking?.state;

    const onSubmit = useCreateBookingFormOnSubmit(booking, setInput, setDidSubmit);

    const onQuestionsAnswered = useCallback(
        (questions: BookingFormValues["questions"]) => {
            const bookingQuestions = booking?.bookingQuestions ?? [];
            const availabilityQuestions =
                booking?.availabilities.flatMap((availability) => availability.questionList.nodes) ?? [];
            const availabilityPersonQuestions =
                booking?.availabilities.flatMap((availability) =>
                    availability.personList.nodes.flatMap((person) => person.questionList.nodes)
                ) ?? [];

            const questionsList = [...bookingQuestions, ...availabilityQuestions, ...availabilityPersonQuestions];
            const normalisedQuestions = normalizeBookingInputAnswerList(questions, questionsList);

            const answerList = Object.entries(normalisedQuestions)
                .filter(([, value]) => value)
                .map(([questionId, value]) => ({ questionId, value: value! }));

            if (!answerList.length) {
                return;
            }

            setInput({ answerList });
        },
        [setInput, booking?.availabilities, booking?.bookingQuestions]
    );

    useBookingFormPaymentPending({
        booking,
        didSubmit,
        showPaymentModal,
        setShowPaymentModal,
        setDidSubmit,
    });

    const onCommit = useCallback(
        async (params?: BookingCommitParams) => {
            setShowPaymentModal(false);
            await bookingCommit(params);
            setShowConfirmModal(true);
        },
        [bookingCommit]
    );

    const initialValues = useBookingFormComputeInitialValues({ booking, isTermsAccepted: termsAccepted });
    const isComplete = booking?.useLifecycleManager ? false : state && state !== "OPEN" && state !== "PAYMENT";
    const isExpired = state && state === "EXPIRED";

    return useMemo(() => {
        const context: BookingFormContextValue = {
            booking,
            isCommitting,
            loading,
            error,
            refetch,
            bookingDeleteAvailability,
            bookingCommit,
            bookingCancel,
            setTermsAccepted,
            termsAccepted,
            showPaymentModal,
            setShowPaymentModal,
            initialValues,
            isComplete,
            isExpired,
            showConfirmModal,
            setShowConfirmModal,
            settings: settings ?? DEFAULT_SETTINGS,
            permissions: permissions ?? DEFAULT_PERMISSIONS,
            onQuestionsAnswered,
            onSubmit,
            onCommit,
        };

        return context;
    }, [
        booking,
        isCommitting,
        loading,
        error,
        refetch,
        bookingDeleteAvailability,
        bookingCommit,
        bookingCancel,
        termsAccepted,
        showPaymentModal,
        initialValues,
        isComplete,
        isExpired,
        showConfirmModal,
        settings,
        permissions,
        onQuestionsAnswered,
        onSubmit,
        onCommit,
    ]);
}

export function useBookingFormContextCreate(params: BookingFormProviderParams) {
    const bookingManagerData = useBookingManager(params);
    return useBookingFormBaseContextCreate(bookingManagerData);
}

export function useBookingFormContext() {
    return useContext(BookingFormContext);
}

export function useBookingFormBooking() {
    const { booking } = useBookingFormContext();

    return booking;
}

export function useBookingChargeType(): BookingChargeType {
    const booking = useBookingFormBooking();
    const { amount } = booking?.outstandingConsumerAmount ?? { amount: 0 };

    if (amount === 0) {
        return BookingChargeTypeSchema.enum.zero;
    }

    if (amount < 0) {
        return BookingChargeTypeSchema.enum.refund;
    }

    return BookingChargeTypeSchema.enum.charge;
}

export function useShowExternalTransactionForm(): boolean {
    const chargeType = useBookingChargeType();
    const { settings } = useBookingFormContext();

    const isChargeOrRefund =
        chargeType === BookingChargeTypeSchema.enum.charge || chargeType === BookingChargeTypeSchema.enum.refund;

    if (!isChargeOrRefund) {
        return false;
    }

    return !!settings.requireExternalTransactionReference;
}

export function useBookingFormLoading() {
    const { loading } = useBookingFormContext();
    return loading;
}

export function useBookingFormIsCommitting() {
    const { isCommitting } = useBookingFormContext();
    return isCommitting;
}

export function useBookingFormTermsAccepted() {
    const { termsAccepted, setTermsAccepted } = useBookingFormContext();
    return [termsAccepted, setTermsAccepted] as const;
}

export function useBookingFormShowPaymentModal() {
    const { showPaymentModal, setShowPaymentModal } = useBookingFormContext();
    return [showPaymentModal, setShowPaymentModal] as const;
}

export function useBookingFormShowConfirmModal() {
    const { showConfirmModal, setShowConfirmModal } = useBookingFormContext();
    return [showConfirmModal, setShowConfirmModal] as const;
}

export function useBookingFormOnPaymentSuccess(onPaymentSuccess?: () => void) {
    const { onCommit } = useBookingFormContext();

    return useCallback(async () => {
        await onCommit();
        if (onPaymentSuccess) onPaymentSuccess();
    }, [onCommit, onPaymentSuccess]);
}

export function useBookingFormInitialValues() {
    const { initialValues } = useBookingFormContext();
    const [initialFormValues, setInitialFormValues] = useState<typeof initialValues>(initialValues);

    if (initialValues && !initialFormValues) {
        setInitialFormValues(initialValues);
    }

    return initialFormValues;
}

export function useBookingAvailabilities() {
    const { booking } = useBookingFormContext();
    const availabilities = booking?.availabilities ?? [];
    return availabilities;
}

export function useBookingVoucherUrl() {
    const { booking } = useBookingFormContext();
    const voucherUrl = booking?.voucherUrl;
    return voucherUrl;
}

export function useBookingFormBookingState() {
    const { booking } = useBookingFormContext();
    const state = booking?.state;
    return state ?? null;
}

export function useBookingFormOnSubmit() {
    const { onSubmit } = useBookingFormContext();
    return onSubmit;
}

export function useBookingFormBookingIsComplete() {
    const { isComplete } = useBookingFormContext();
    return isComplete;
}

export function useBookingFormBookingIsPendingCommit() {
    const { booking } = useBookingFormContext();
    return !!booking?.isPendingCommit;
}

export function useBookingFormHasPendingAvailability() {
    const { booking } = useBookingFormContext();

    return booking?.availabilities.some((bk) => PENDING_AVAILABILITY_STATES.includes(bk.state));
}

export function useBookingFormStickyBottomCardIsVisible() {
    const isBookingCommitting = useBookingFormIsCommitting();
    const hasUnsavedChanges = useBookingFormBookingHasUnsavedChanges();

    return isBookingCommitting || hasUnsavedChanges;
}

export function useBookingFormBookingHasUnsavedChanges() {
    const { booking } = useBookingFormContext();

    const bookingAvailabilities = booking?.availabilities ?? [];

    const pendingAvailabilities = bookingAvailabilities.some((bk) => PENDING_AVAILABILITY_STATES.includes(bk.state));
    const openAvailabilities = bookingAvailabilities.filter((availability) => availability.state === "OPEN");

    const areAllOpened = openAvailabilities.length === bookingAvailabilities.length;

    return !!pendingAvailabilities || (openAvailabilities.length > 0 && !areAllOpened);
}

export function useBookingFormBookingTotalGross() {
    const { booking } = useBookingFormContext();

    const bookingTotalGrossPrice = booking?.totalPrice.gross ?? 0;

    if (booking?.useLifecycleManager) {
        return bookingTotalGrossPrice;
    }

    const bookingAvailabilities = booking?.availabilities ?? [];

    const bookingAvailabilitiesTotalGrossPrice = bookingAvailabilities
        .filter(
            (x) =>
                x.state !== BookingAvailabilityProcessState.Amended &&
                x.state !== BookingAvailabilityProcessState.Cancelled
        )
        .reduce((acc, x) => acc + x.totalPrice.gross, 0);

    return bookingAvailabilitiesTotalGrossPrice;
}

export function useBookingOutstandingPriceSummaryLabel(price: number) {
    const [t] = useNextTranslation("booking");
    return t(price > 0 ? "label.outstandingCost" : "label.refundAmount");
}

export function useBookingFormBookingIsExpired() {
    const { isExpired } = useBookingFormContext();
    return isExpired;
}

export function useBookingFormError() {
    const { error } = useBookingFormContext();
    return error;
}

export function useBookingFormBookingQuestions() {
    const { booking } = useBookingFormContext();
    const bookingQuestions = booking?.bookingQuestions ?? [];
    return bookingQuestions;
}
export function useBookingFormIsReadOnly() {
    const booking = useBookingFormBooking();
    const isPendingCommit = useBookingFormBookingIsPendingCommit();
    const isCommitting = useBookingFormIsCommitting();
    const state = useBookingFormBookingState();

    if (isCommitting) {
        return true;
    }

    if (!booking?.useLifecycleManager) {
        return state !== "OPEN";
    }

    return !isPendingCommit;
}

export function useBookingFormDeleteAvailability() {
    const { bookingDeleteAvailability } = useBookingFormContext();
    return bookingDeleteAvailability;
}

export function useBookingFormCancelBooking() {
    const { bookingCancel } = useBookingFormContext();
    return bookingCancel;
}

export function useBookingFormBookingCanCancel() {
    const booking = useBookingFormBooking();

    return booking?.canCancel;
}

export function useBookingCancellationEffectiveRefundAmount() {
    const booking = useBookingFormBooking();

    return booking?.cancellationEffectiveRefundAmount;
}

export function useBookingFormSettings() {
    const { settings } = useContext(BookingFormContext);
    return settings;
}

export function useBookingFormPermissions() {
    const { permissions } = useContext(BookingFormContext);
    return permissions;
}

export type Penalty = {
    id: string;
    cancellationDescription: string | null | undefined;
    productName: string | null;
};

export function useBookingAvailabilityCancellationPenalties(): Penalty[] {
    const [t] = useNextTranslation("product");
    const noCancellationPolicyLabel = t("description.cancellationPolicy");
    const availabilities = useBookingAvailabilities();

    return useMemo(() => {
        return availabilities.map((availability) => {
            const { id, cancellationState, product } = availability;
            const productName = product.name;

            let cancellationDescription;
            if (cancellationState === null) {
                cancellationDescription = noCancellationPolicyLabel;
            }
            if (cancellationState) {
                const effectivePenalty = cancellationState.effectivePenalty;
                cancellationDescription = effectivePenalty.formattedText;
            }

            return { id, cancellationDescription, productName };
        });
    }, [availabilities, noCancellationPolicyLabel]);
}

export function useAvailabilityRequiresCommitting(availability: BookingAvailability) {
    const bookingHasUnsavedChanges = useBookingFormBookingHasUnsavedChanges();

    const hasBeenAmended = !!(
        availability.state === BookingAvailabilityProcessState.Open &&
        availability.originBookingAvailability &&
        availability.originBookingAvailability.state === BookingAvailabilityProcessState.PendingAmendment
    );

    return (
        hasBeenAmended ||
        availability.state === BookingAvailabilityProcessState.PendingCancellation ||
        (bookingHasUnsavedChanges && availability.state === BookingAvailabilityProcessState.Open)
    );
}

function normalizeBookingInputAnswerList(
    questions: BookingFormValues["questions"],
    questionList: Pick<BookingQuestion, "id" | "dataFormat">[]
): BookingFormValues["questions"] {
    const normalisedQuestions: BookingFormValues["questions"] = {};
    for (const [key, value] of Object.entries(questions)) {
        if (!value) {
            continue;
        }

        const question = questionList.find((question) => question.id === key);
        if (!question) {
            continue;
        }

        normalisedQuestions[key] = normalizeBookingFormValue(question.dataFormat, value);
    }

    return normalisedQuestions;
}

type FormValueNormalizer = (value: string) => string | null;

const normalizers: Record<string, FormValueNormalizer | null> = {
    PHONE_NUMBER: (value) => {
        const parsedPhoneNumber = PhoneNumberFormattedE164Schema.safeParse(value);
        return parsedPhoneNumber.success ? parsedPhoneNumber.data : null;
    },
    EMAIL_ADDRESS: (value) => {
        const parseEmail = z.string().email().safeParse(value);
        return parseEmail.success ? parseEmail.data : null;
    },
    // Add future normalizers here...
};

function normalizeBookingFormValue(dataFormat: string | null, value: string): string | null {
    if (!dataFormat) {
        return value;
    }

    const normalizer = normalizers[dataFormat];
    return normalizer ? normalizer(value) : value;
}
