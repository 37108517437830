import { fromTry, left, right } from "@sweet-monads/either";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { z } from "zod";

const phoneNumberUtil = PhoneNumberUtil.getInstance();
export const PhoneNumberBasicSchema = z
    .string()
    .min(10)
    .transform((value, ctx) => {
        return fromTry(() => phoneNumberUtil.parseAndKeepRawInput(value))
            .mapLeft(() => "Invalid phone number")
            .chain((parsedPhone) => {
                if (phoneNumberUtil.isValidNumber(parsedPhone)) {
                    return right(parsedPhone);
                }
                return left("Invalid phone number");
            })
            .fold(
                (message) => {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message,
                    });
                    return z.NEVER;
                },
                (x) => x
            );
    });

/**
 * Returns phone number formatted to international format
 */
export const PhoneNumberFormattedSchema = PhoneNumberBasicSchema.transform((value) => {
    return phoneNumberUtil.format(value, PhoneNumberFormat.INTERNATIONAL);
});

/**
 * Returns phone number formatted to E.164 format
 */
export const PhoneNumberFormattedE164Schema = PhoneNumberBasicSchema.transform((value) => {
    return phoneNumberUtil.format(value, PhoneNumberFormat.E164);
});

/**
 * Returns phone number unformatted
 */
export const PhoneNumberUnformattedSchema = PhoneNumberBasicSchema.transform((value) => {
    return value.getRawInputOrDefault();
});

export type PhoneNumber = string;
