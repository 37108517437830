import authentication from "./authentication";
import availability from "./availability";
import availabilityForm from "./availabilityForm";
import booking from "./booking";
import bookingAvailability from "./bookingAvailability";
import boxSpacing from "./boxSpacing";
import entityLabel from "./entityLabel";
import explore from "./explore";
import externalWidget from "./externalWidget";
import general from "./general";
import hbml from "./hbml.json";
import headerNavigation from "./headerNavigation";
import language from "./language";
import product from "./product";
import questionnaire from "./questionnaire.json";

const namespaces = {
    availability,
    availabilityForm,
    authentication,
    booking,
    bookingAvailability,
    general,
    headerNavigation,
    language,
    product,
    questionnaire,
    externalWidget,
    boxSpacing,
    hbml,
    explore,
    entityLabel,
};

export default namespaces;
